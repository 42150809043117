import { Outlet, Route, Routes } from "react-router-dom";
import { Suspense, lazy } from "react";

import FourOneFour from "./components/other/404";
import HomePage from "./pages/home";

const About = lazy(() => import("./pages/about"));
const Privacy = lazy(() => import("./pages/privacy"));

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<HomePage />} />
        <Route
          path="/about"
          element={
            <Suspense fallback={<>...</>}>
              <About />
            </Suspense>
          }
        />
        <Route
          path="/privacy"
          element={
            <Suspense fallback={<>...</>}>
              <Privacy />
            </Suspense>
          }
        />
        <Route path="*" element={<FourOneFour />} />
      </Route>
    </Routes>
  );
}

function Layout() {
  return (
    <div id="main">
      <Outlet />
    </div>
  );
}

export default App;

import { ApolloClient, ApolloProvider, InMemoryCache, createHttpLink } from "@apollo/client";

import App from "./App";
import { CookiesProvider } from "react-cookie";
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { createRoot } from "react-dom/client";
import jsonConfig from "./config.json";

const link = createHttpLink({
  uri: `${jsonConfig.address}/api/graphql`,
  credentials: "include",
});

const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});

const container = document.getElementById("root");
const root = createRoot(container); //

root.render(
  <React.StrictMode>
    <Router>
      <ApolloProvider client={client}>
        <CookiesProvider>
          <App />
        </CookiesProvider>
      </ApolloProvider>
    </Router>
  </React.StrictMode>
);

import { Link } from "react-router-dom";

export default function FourOneFour() {
  return (
    <>
      <header className="header">
        <div className="headercontent">
          <div className="headercontent2 buttonmove">
            <a className="button" href="/">
              Home
            </a>
            <a className="button" href="#faq">
              FAQ
            </a>
            <a className="button" target="_blank" rel="noopener noreferrer" href="https://youtu.be/2XXKzkMzXbk?t=93">
              Help
            </a>
          </div>
          <div className="headerlogo">
            <a href="/">
              <img alt="KahootBotter.com Logo" src="https://i.moofie.xyz/kahoot/logominified.png" decoding="async" />
            </a>
          </div>
          <div className="headercontent2 Header_content_links_right__1JjMY buttonmove">
            <a
              className="button discord"
              target="_blank"
              rel="noopener noreferrer"
              href="https://kahootbotter.com/discord"
            >
              Discord
            </a>
            <a
              className="button twitter"
              target="_blank"
              rel="noopener noreferrer"
              href="https://twitter.com/KahootBotterCom"
            >
              Twitter
            </a>
            <a
              className="button youtube"
              target="_blank"
              rel="noopener noreferrer"
              href="https://kahootbotter.com/youtube"
            >
              YouTube
            </a>
          </div>
        </div>
      </header>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          width: "100%",
          minHeight: "400px",
          position: "relative",
        }}
      >
        <img
          style={{
            margin: "20px auto",
            width: "80%",
            maxHeight: "500px",
          }}
          src="https://i.moofie.xyz/kahoot/404.svg"
          alt="404"
        ></img>
        <p
          style={{
            color: "white",
            fontSize: "30px",
            fontWeight: "700",
          }}
        >
          <Link to="/">Go to the home page.</Link>
        </p>
      </div>
      <section className="footersections">
        <div className="sectioncontent">
          <footer className="footer">
            <div>
              <div className="footerlogo">
                <div className="footerlogobox">
                  <a href="/">
                    <img
                      loading="lazy"
                      alt="KahootBotter's Kahoot Smasher"
                      aria-hidden="true"
                      role="presentation"
                      src="https://i.moofie.xyz/kahoot/logominified.png"
                    />
                  </a>
                </div>
              </div>
              <p className="footertext">
                Hack any Kahoot Lobbies in only <strong>seconds</strong> with our completely free Kahoot Bot!
              </p>
            </div>
            <div className="footerspacer" />
            <div className="footerlinks">
              <section>
                <p className="title">Pages</p>
                <div>
                  <a target="_blank" rel="noopener noreferrer" href="/">
                    Home
                  </a>
                  <a href="#faq">FAQ</a>
                  <a target="_blank" rel="noopener noreferrer" href="https://youtu.be/2XXKzkMzXbk?t=93">
                    Help
                  </a>
                  <a target="_blank" rel="noopener noreferrer" href="/discord">
                    Advertising
                  </a>
                </div>
              </section>
              <section>
                <p className="title">Social Media</p>
                <div>
                  <a target="_blank" rel="noopener noreferrer" href="https://kahootbotter.com/discord">
                    Discord
                  </a>
                  <a target="_blank" rel="noopener noreferrer" href="https://t.me/KahootBotter">
                    Telegram
                  </a>
                  <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/KahootBotterCom">
                    Twitter
                  </a>
                  <a target="_blank" rel="noopener noreferrer" href="https://kahootbotter.com/youtube">
                    YouTube
                  </a>
                </div>
              </section>
              <section>
                <p className="title">Also made by us</p>
                <div>
                  <a target="_blank" rel="noopener noreferrer" href="https://alts.top">
                    Alts.TOP
                  </a>
                  <a target="_blank" rel="noopener noreferrer" href="https://MinecraftAlt.com">
                    MinecraftAlt.com
                  </a>
                  <a target="_blank" rel="noopener noreferrer" href="https://FreeAlts.PW">
                    FreeAlts.PW
                  </a>
                </div>
              </section>
            </div>
          </footer>
        </div>
      </section>
    </>
  );
}

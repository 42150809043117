import "../css/extra.css";
import "../css/font.css";
import "react-toastify/dist/ReactToastify.css";

import { Controller, useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";

import config from "../config.json";
import { spawnBotsQuery } from "../graphql/mutation";
import { useCookies } from "react-cookie";
import { useMutation } from "@apollo/client";

export default function HomePage() {
  const { control, handleSubmit } = useForm();
  const [spawnBots] = useMutation(spawnBotsQuery);
  const [cookies, setCookie] = useCookies(["token"]);

  const onSubmit = async (values) => {
    document.getElementById("submit-form-button").disabled = true;

    if (!values || !values.gamePin || values.gamePin.length === 0) {
      toast.error("Bad request - Gamepin empty", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "colored",
      });
      setTimeout(function () {
        document.getElementById("submit-form-button").disabled = false;
      }, 3000);
      return;
    }

    setTimeout(function () {
      document.getElementById("submit-form-button").disabled = false;
    }, 60000);

    setCookie("token", values.gamePin);
    console.log(cookies);

    const id = toast.loading("Bots are loading...", {
      position: "top-center",
      theme: "colored",
    });

    spawnBots({
      variables: {
        botName: values.botName,
        gamePin: Number(values.gamePin),
        botAmount: Number(values.botAmount),
      },
    }).then((result) => {
      console.log(result);
      if (!result || !result.data || !result.data.spawnBots) {
        toast.update(id, {
          render: "Something went wrong!",
          type: "error",
          isLoading: false,
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          closeButton: true,
        });
      } else {
        toast.update(id, {
          render: result.data.spawnBots.description,
          type: result.data.spawnBots.status,
          isLoading: false,
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          closeButton: true,
        });
      }
    });
  };

  return (
    <>
      <div id="__next">
        <div>
          <header className="header">
            <div className="headercontent">
              <div className="headercontent2 buttonmove">
                <a className="button" href="/">
                  Home
                </a>
                <a className="button" href="#faq">
                  FAQ
                </a>
                <a
                  className="button"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://youtu.be/2XXKzkMzXbk?t=93"
                >
                  Help
                </a>
              </div>
              <div className="headerlogo">
                <a href="/">
                  <img
                    alt="KahootBotter.com Logo"
                    src="https://i.moofie.xyz/kahoot/logominified.png"
                    decoding="async"
                  />
                </a>
              </div>
              <div className="headercontent2 Header_content_links_right__1JjMY buttonmove">
                <a
                  className="button discord"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://kahootbotter.com/discord"
                >
                  Discord
                </a>
                <a
                  className="button twitter"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://twitter.com/KahootBotterCom"
                >
                  Twitter
                </a>
                <a
                  className="button youtube"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://kahootbotter.com/youtube"
                >
                  YouTube
                </a>
              </div>
            </div>
          </header>

          <ToastContainer />

          <div className="gaps">
            <div className="homemargin">
              <section className="sections sectiontop">
                <div className="sectioncontent">
                  <section>
                    <div className="welcomeall">
                      <div className="welcomeleft">
                        <div className="hello">
                          <h1>The BEST Kahoot Smasher & Spammer</h1>
                          <p>
                            Hack any Kahoot Lobby in <strong>only seconds</strong> with our completely free Kahoot Bot.
                          </p>
                          <div className="helloButtons">
                            <a target="_blank" rel="noopener noreferrer" href="https://youtu.be/2XXKzkMzXbk?t=93">
                              <button className="go">See how it works</button>
                            </a>
                            <div className="helloButtonsSocial">
                              <a
                                className="button HomePage_hero_contact__HoKN0"
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://kahootbotter.com/discord"
                              >
                                <div className="helloButtonsSocial1">
                                  <div className="helloButtonsSocial2">
                                    <img
                                      className="helloButtonsSocial3"
                                      alt="KahootBotter discord"
                                      aria-hidden="true"
                                      role="presentation"
                                      src="https://i.moofie.xyz/kahoot/discord.png"
                                    />
                                  </div>
                                </div>
                              </a>
                              <a
                                className="button HomePage_hero_contact__HoKN0"
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://kahootbotter.com/twitter"
                              >
                                <div className="helloButtonsSocial1">
                                  <div className="helloButtonsSocial2">
                                    <img
                                      className="helloButtonsSocial3"
                                      alt="KahootBotter Twitter"
                                      aria-hidden="true"
                                      role="presentation"
                                      src="https://i.moofie.xyz/kahoot/twitter.png"
                                    />
                                  </div>
                                </div>
                              </a>
                              <a
                                className="button HomePage_hero_contact__HoKN0"
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://kahootbotter.com/youtube"
                              >
                                <div className="helloButtonsSocial1">
                                  <div className="helloButtonsSocial2">
                                    <img
                                      className="helloButtonsSocial3"
                                      alt="KahootBotter Youtube"
                                      aria-hidden="true"
                                      role="presentation"
                                      src="https://i.moofie.xyz/kahoot/youtube.png"
                                    />
                                  </div>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="sponsor">
                          <div>
                            <p className="title">Advertisement</p>
                          </div>
                        </div>
                      </div>
                      <div className="botter">
                        <p className="title">Kahoot Botter 🤖</p>
                        <div>
                          <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="inputTop ">
                              <p>Bot Name</p>
                              <div className="HomePage_hero_input_main__jebsL">
                                <Controller
                                  control={control}
                                  name="botName"
                                  render={({ field: { onChange, name } }) => (
                                    <input
                                      placeholder="KahootBotter.com"
                                      //isrequired="true"
                                      name={name}
                                      type="text"
                                      onChange={onChange}
                                      fontSize="15"
                                    />
                                  )}
                                />
                              </div>
                            </div>
                            <div className="HomePage_hero_main_right_form_bottom__1UEUv">
                              <div className="inputTop spacer">
                                <p>Game Pin</p>
                                <div className="HomePage_hero_input_main__jebsL">
                                  <Controller
                                    control={control}
                                    name="gamePin"
                                    render={({ field: { onChange, name } }) => (
                                      <input
                                        type="number"
                                        //isrequired="true"
                                        name={name}
                                        onChange={onChange}
                                        placeholder="594531"
                                      />
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="amount spacer HomePage_hero_main_right_form_bottom_control__rBaSX">
                                <p>Amount</p>
                                <div className="HomePage_hero_input_main__jebsL">
                                  <Controller
                                    control={control}
                                    name="botAmount"
                                    render={({ field: { onChange, name } }) => (
                                      <input
                                        type="number"
                                        //isrequired="true"
                                        min={0}
                                        max={config.maxAmount}
                                        name={name}
                                        onChange={onChange}
                                        placeholder="50"
                                      />
                                    )}
                                  />
                                  <div className="HomePage_hero_input_control__20gyX">
                                    <svg
                                      aria-hidden="true"
                                      focusable="false"
                                      data-prefix="fas"
                                      data-icon="plus"
                                      className="svg-inline--fa fa-plus "
                                      role="img"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 448 512"
                                    >
                                      <path
                                        fill="currentColor"
                                        d="M432 256c0 17.69-14.33 32.01-32 32.01H256v144c0 17.69-14.33 31.99-32 31.99s-32-14.3-32-31.99v-144H48c-17.67 0-32-14.32-32-32.01s14.33-31.99 32-31.99H192v-144c0-17.69 14.33-32.01 32-32.01s32 14.32 32 32.01v144h144C417.7 224 432 238.3 432 256z"
                                      />
                                    </svg>
                                    <svg
                                      aria-hidden="true"
                                      focusable="false"
                                      data-prefix="fas"
                                      data-icon="minus"
                                      className="svg-inline--fa fa-minus "
                                      role="img"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 448 512"
                                    >
                                      <path
                                        fill="currentColor"
                                        d="M400 288h-352c-17.69 0-32-14.32-32-32.01s14.31-31.99 32-31.99h352c17.69 0 32 14.3 32 31.99S417.7 288 400 288z"
                                      />
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <button
                              id="submit-form-button"
                              type="submit"
                              className="sendbots HomePage_hero_main_right_send__3-ji2"
                            >
                              Send Bots
                            </button>
                          </form>
                          <p className="HomePage_hero_main_right_help__2DM3y">
                            Need help?{" "}
                            <a href="https://youtu.be/2XXKzkMzXbk?t=93">
                              <strong>Check out a tutorial</strong>
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                    <a id="faq" href="#faq">
                      <span></span>
                    </a>
                    <div className="down">
                      <a href="#faq">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                          <path d="M224 416c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L224 338.8l169.4-169.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-192 192C240.4 412.9 232.2 416 224 416z"></path>
                        </svg>
                      </a>
                    </div>
                  </section>
                </div>
              </section>
              <section className="sections purple">
                <div className="sectioncontent">
                  <section>
                    <div className="faqHeader">
                      <p className="faqHeaderTitle">FAQ</p>
                      <div className="faqHeaderDesc">
                        <h2>Frequently Asked Questions</h2>
                        <h3>
                          Got other questions? Join
                          {/* */}{" "}
                          <a href="/discord">
                            <strong>our discord.</strong>
                          </a>
                        </h3>
                      </div>
                    </div>
                    <div className="faqQuestions">
                      <div className="faqQuestionLeft">
                        <div className="faqQuestion">
                          <p className="title">Why should I use KahootBotter.com?</p>
                          <div>
                            <p>
                              KahootBotter utilizes our own in-house designed Kahoot Bot built specifically with{" "}
                              <strong>efficiency and speed and speed</strong> in mind, and coupled with our High Speed
                              servers, we can guarantee 24x7 reliability for when you need to destroy Kahoot Lobbies
                              with our bots, for <strong>absolutely free</strong>.
                            </p>
                          </div>
                        </div>
                        <div className="faqQuestion">
                          <p className="title">Why is the Kahoot Bot free?</p>
                          <div>
                            <p>
                              We aim to provide a reliable, yet free solution for Smashing and Botting Kahoots. By
                              displaying ads to our users, we are able to completely pay for the development of our
                              Kahoot Bot along with the cost of hosting the Bot.
                            </p>
                          </div>
                        </div>
                        <div className="faqQuestion">
                          <p className="title">How can I support KahootBotter?</p>
                          <div>
                            <p>
                              By simply turning off your adblocker, we are able to make enough money to keep our Kahoot
                              Bot and Hacks up and running for free along with development. However if you’d like to
                              further support us, we do offer custom advertisement services and donations in Crypto.
                            </p>
                          </div>
                        </div>
                        <div className="faqQuestion">
                          <p className="title">What’s the max amount of bots?</p>
                          <div>
                            <p>
                              For us to provide the best experience possible, the <strong>current</strong> limit is{" "}
                              <strong>50 bots</strong>. This may change over time, and sometimes we temporarily increase
                              the limit for certain occasions. We suggest{" "}
                              <a href="/discord">
                                <strong>joining our discord</strong>
                              </a>{" "}
                              to know when this happens.
                            </p>
                          </div>
                        </div>
                        <div className="faqQuestion">
                          <p className="title">Will using the Kahoot Bot get me in trouble?</p>
                          <div>
                            <p>
                              We can safely say that using our Kahoot Flooder will not get you in any sort of trouble.
                              Our Kahoot Bot is discretely coded to hide the user of the bots and spoof connections to
                              Kahoot servers so that they cannot be traced back to the user. When using our Kahoot Bot
                              you can stay rest assured that you will be anonymous!
                            </p>
                          </div>
                        </div>
                        <div className="faqQuestion">
                          <p className="title">How does a Kahoot Bot work?</p>
                          <div>
                            <p>
                              KahootBotter works similar to other Kahoot Bots that were previously (and may still be)
                              available such as Kahoot Bot, Kahoot Killer, Kahoot Ninja, Kahoot Rocks, Kahoot Crasher,
                              Kahoot Spammer, Kahoot Smasher, Kahoot Hack, and Kahoot Joiner. KahootBotter provides a
                              simple and easy to use interface that is connected to a server coded to efficiently spoof
                              requests utilizing anonymous IP Addresses (proxies) to Kahoot servers with the information
                              that you provide. All this is done while you can simply sit back, and watch our bots join
                              and Hack Kahoot for you.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="faqQuestionRight">
                        <div className="faqQuestionImg">
                          <p className="title">Does the Botter answer questions?</p>
                          <div>
                            <p className="answer">
                              Our Kahoot Bot currently only answers randomly consistently, however correct answers are
                              planned to be added to the Kahoot Smasher in the near future as we are constantly working
                              on a bypass for our Kahoot Bot!
                            </p>
                            <img
                              loading="lazy"
                              alt="Randomized answers from our Kahoot Bot"
                              aria-hidden="true"
                              role="presentation"
                              src="https://i.moofie.xyz/kahoot/FAQ1.png"
                              width="478px"
                              height="202px"
                            />
                          </div>
                        </div>
                        <div className="faqQuestionImg">
                          <p className="title">Does the Spammer actually work?</p>
                          <div>
                            <p className="answer">
                              Our Kahoot Spammer is currently the <strong>most popular</strong> Kahoot Bot and is not
                              only guaranteed to always work, but also works <strong>far better</strong> and{" "}
                              <strong>stronger</strong> than other competitor Kahoot Bots available.
                            </p>
                            <img
                              loading="lazy"
                              alt="Proof that our Kahoot Bot works efficiently"
                              aria-hidden="true"
                              role="presentation"
                              src="https://i.moofie.xyz/kahoot/FAQ2.png"
                              width="478px"
                              height="202px"
                            />
                          </div>
                        </div>
                        <div className="faqQuestionImg">
                          <p className="title">How fast is the Kahoot Bot?</p>
                          <div>
                            <p className="answer">
                              Why settle for less when you can get more? Our Kahoot Spammer is extremely efficiently
                              coded and can easily flood any Kahoot lobby in just a few seconds while barely utilizing
                              any of our available cpu resources!
                            </p>
                            <img
                              loading="lazy"
                              alt="KahootBot's efficiently coded cheat barely utilizes any cpu usage"
                              aria-hidden="true"
                              role="presentation"
                              src="https://i.moofie.xyz/kahoot/FAQ3.png"
                              width="478px"
                              height="335px"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </section>
            </div>
            <section className="footersections">
              <div className="sectioncontent">
                <footer className="footer">
                  <div>
                    <div className="footerlogo">
                      <div className="footerlogobox">
                        <a href="/">
                          <img
                            loading="lazy"
                            alt="KahootBotter's Kahoot Smasher"
                            aria-hidden="true"
                            role="presentation"
                            width="240px"
                            height="134px"
                            src="https://i.moofie.xyz/kahoot/logominified.png"
                          />
                        </a>
                      </div>
                    </div>
                    <p className="footertext">
                      Hack any Kahoot Lobbies in only <strong>seconds</strong> with our completely free Kahoot Bot!
                    </p>
                  </div>
                  <div className="footerspacer" />
                  <div className="footerlinks">
                    <section>
                      <p className="title">Pages</p>
                      <div>
                        <a href="/">Home</a>
                        <a href="#faq">FAQ</a>
                        <a href="/about">About Us</a>
                        <a href="/privacy">Privacy Policy</a>
                      </div>
                    </section>
                    <section>
                      <p className="title">Social Media</p>
                      <div>
                        <a target="_blank" rel="noopener noreferrer" href="https://kahootbotter.com/discord">
                          Discord
                        </a>
                        <a target="_blank" rel="noopener noreferrer" href="https://t.me/KahootBotter">
                          Telegram
                        </a>
                        <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/KahootBotterCom">
                          Twitter
                        </a>
                        <a target="_blank" rel="noopener noreferrer" href="https://kahootbotter.com/youtube">
                          YouTube
                        </a>
                      </div>
                    </section>
                    <section>
                      <p className="title">Also made by us</p>
                      <div>
                        <a target="_blank" rel="noopener noreferrer" href="https://alts.top">
                          Alts.TOP
                        </a>
                        <a target="_blank" rel="noopener noreferrer" href="https://MinecraftAlt.com">
                          MinecraftAlt.com
                        </a>
                        <a target="_blank" rel="noopener noreferrer" href="https://FreeAlts.PW">
                          FreeAlts.PW
                        </a>
                      </div>
                    </section>
                  </div>
                </footer>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
}

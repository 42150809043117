import { gql } from "@apollo/client";

export const spawnBotsQuery = gql`
  mutation spawnBots($botName: String!, $gamePin: Int!, $botAmount: Int!) {
    spawnBots(botName: $botName, gamePin: $gamePin, botAmount: $botAmount) {
      title
      status
      description
    }
  }
`;
